<template>
    <div id="config-list">
        <div class="container">
            <div class="row clearfix">
                <div class="col-md-12">
                    <h2 class="hd-title">评测语言</h2>
                    <div class="col-md-12">
                        <el-row :gutter="20">
                            <el-form :inline="true">
                                <el-col :span="4" :offset="20">
                                    <el-button type="primary" size="small" round @click="add_new_compiler">新增</el-button>
                                </el-col>
                            </el-form>
                        </el-row>
                    </div>
                    <el-divider></el-divider>
                    <el-table :data="language_list" style="width: 100%">
                        <el-table-column label="启用" align="center" width="80">
                            <template slot-scope="scope">
                                <span v-if="scope.row.enable == 0">
                                    <el-tag type="danger"> 未启用 </el-tag>
                                </span>
                                <span v-else>
                                    <el-tag type="success">已启用</el-tag>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="language" label="语言" align="center" width="180">
                        </el-table-column>
                        <el-table-column prop="version" label="版本" align="center" width="180">
                        </el-table-column>
                        <el-table-column prop="compiler" label="编译器" align="center">
                        </el-table-column>
                        <el-table-column prop="update_time" align="center" label="更新时间">
                            <template slot-scope="scope">
                                <span> {{fromStrToCNTime(scope.row.update_time)}} </span>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" align="center" width="300">
                            <template slot-scope="scope">
                                <el-button @click="operate_compiler(scope.row.compiler, 'remove')" type="text" size="small">
                                    删除
                                </el-button>
                                <el-button @click="operate_compiler(scope.row.compiler, 'enable')" type="text" size="small">
                                    启用
                                </el-button>
                                <el-button @click="operate_compiler(scope.row.compiler, 'disable')" type="text" size="small">
                                    停用
                                </el-button>
                                <el-button @click="edit_compiler(scope.row.compiler)" type="text" size="small">
                                    编辑
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <el-divider/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { dateFormat } from '../../filters/custom.js'
import { getCompilerList, operate_compiler_api } from '../../api/config.js'
export default {
    name: "config-list",
    data() {
        return {
            language_list: []
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            getCompilerList().then(resp=>{
                let body = resp.data
                if (body.status == 'success')
                    this.language_list = body.list
                else
                    console.log(body)
            }).catch(e=>{
                console.log(e)
            })
        },
        fromStrToCNTime(str) {
            let date = str.substring(0, 19);
            date = date.replace(/-/g, '/'); //将'-'转化为'/'是因为有的手机上new Date('2020-09-06 00:00:00')会报错，所以统一转化为'2020/09/06 00:00:00'格式
            let time = new Date(date).getTime() + 8 * 60 * 60 * 1000; //考虑时区的差异，统一转化为东八区时间
            let cn_time_str = dateFormat(time)
            return cn_time_str;
        },
        add_new_compiler() {
            this.$router.push({path: '/front/config/detail'})
        },
        edit_compiler(compiler) {
            this.$router.push({path: '/front/config/detail?compiler=' + compiler})
        },
        operate_compiler(compiler, op) {
            operate_compiler_api(compiler, op).then(resp=>{
                let body = resp.data
                if (body.status == 'success') {
                    this.$message({
                        showClose: true,
                        message: '操作成功',
                        type: 'success'
                    })

                    this.fetchData()
                } else {
                    this.$message({
                        showClose: true,
                        message: '操作失败',
                        type: 'error'
                    })
                }
            }).catch(e=>{
                this.$message({
                    showClose: true,
                    message: '操作失败',
                    type: 'error'
                })
                console.log(e)
            })
        }
    }
}
</script>
<style scoped>
</style>