<template>
    <div id="submission-result">
        <div class="container">
            <div class="row clearfix">
                <div class="col-md-12">
                    <div class="page-header">
                        <h3>Submission Result</h3>
                    </div>
                    <el-divider></el-divider>
                    <div>
                        <blockquote style="text-align:left">
                            <p>Update Time</p>
                            <pre>{{update_time}}</pre>
                            <p>Problem ID</p>
                            <pre>{{guid}} </pre>
                            <p>Source Code</p>
                            <div id="code2" class="my">
                                <codemirror ref="myCm"
                                    :value="code"
                                    :options="cmOptions">
                                </codemirror>
                            </div>

                            <p>Compile Output</p>
                            <pre>{{compile}}</pre>
                        </blockquote>

                    </div>
                    <table class="table">
                        <thead>
                        <tr class="text-center">
                            <th>Case</th>
                            <th>Status</th>
                            <th>Time</th>
                            <th>Memory</th>
                            <th>StdErr</th>
                            <th>Data</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr v-for="value in resultData">
                            <td>{{value.caseNum}}</td>
                            <td :style="{ color: colorText(value.status)}">{{value.status}}</td>
                            <td>{{value.running_time}}</td>
                            <td>{{value.running_memory}}</td>
                            <td>{{value.stderr}}</td>
                            <td>
                                <div v-html="value.data" align="left">
                                </div>
                            </td>
                        </tr>

                        <tr  v-show="resultData.length==0">
                            <td colspan="4" class="text-center text-muted">
                                <p>暂无数据</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getSubmissionResult} from '../../api/queue'


    export default {
        data () {
            return {
                run_id: 0,
                compile: '',
                update_time: 'loading',
                guid: '',
                code: 'loading',
                cmOptions: {
                    // codemirror options
                    readOnly: true,
                    mode: 'text/x-c++src',
                    theme: 'eclipse',
                    lineNumbers: true,
                    line: true,
                    refresh: true,
                    // more codemirror options, 更多 codemirror 的高级配置...
                  },
                resultData: []
            }
        },
        mounted () {
            if ('run_id' in this.$route.query) {
                this.run_id = this.$route.query.run_id
            }
            this.fetchData()
        },
        methods: {
            colorText: function (result) {
                if (result === 'Accepted') return 'red'
                if (result === 'Wrong Answer') return 'green'
                if (result === 'Runtime Error') return 'purple'
                if (result === 'Memory Limit Execeed') return 'blue'
                if (result === 'Invalid Memory Reference') return '#66FFFF'
                if (result === 'Compile Error') return 'green'
            },
            fetchData () {
                getSubmissionResult(this.run_id).then(response => {
                    let data = response.data
                    console.log(data)
                    this.compile = data.result.compile
                    console.log(this.compile)
                    this.code = data.result.code
                    this.update_time = data.result.update_time
                    this.guid = data.result.guid
                    if ('tests' in data.result && data.result.tests != '' && data.result.tests != null && data.result.tests !='null') {
                        this.resultData = JSON.parse(data.result.tests)
                        let tests = this.resultData
                        for (let i=0; i < tests.length; i++) {
                            if (tests[i].stdin)
                                tests[i].stdin = tests[i].stdin.replace(/\n/g, "<br/>")
                            if (tests[i].stdout) 
                                tests[i].stdout = tests[i].stdout.replace(/\n/g, "<br/>")
                            if (tests[i].answer)
                                tests[i].answer = tests[i].answer.replace(/\n/g, "<br/>")
                            if (tests[i].stdin && tests[i].stdout && tests[i].answer) {
                                tests[i].data = "input:<br/>" + tests[i].stdin + "<br/>output:</br>" + tests[i].stdout + "<br/>answer:<br/>" + tests[i].answer
                            }
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped>

pre {
  background: white;
}


</style>

<style scoped lang="css">

.my >>> .CodeMirror {
    height:100%;
}
</style>
