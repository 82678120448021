import request from '@/utils/request'

// 上传题目
export function uploadProblem (formData) {
  return request({
    url: '/admin/upload_problem',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  })
}

// 提交答案
export function submitAnswer (guid, title, language, code, times) {
    return request({
        url: '/server/problem/submit_answer',
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            guid: guid,
            title: title,
            language: language,
            code: code,
            times: times
        }
    })
}

// 获取题目列表
export function getProblems (page, client, title, guid) {
    return request({
        url: '/server/problem',
        method: 'get',
        params: {
          page: page,
            client: client,
            title: title,
            guid: guid
        }
    })
}

export function getProblemDetail (guid) {
    return request({
        url: '/server/problem/detail',
        method: 'get',
        params: {
            guid: guid
        }
    })
}