<template>
    <div id="problems">
        <div class="container">
            <div class="row clearfix">
                <div class="col-md-12 column">
                    <h2 class="hd-title">problems</h2>
                    <el-form :inline="true" size="small">
                        <el-row>
                            <el-form-item label="账号:">
                                <el-input v-model="client" @change="fetchData()" />
                            </el-form-item>
                            <el-form-item label="题目:">
                                <el-input v-model="title" @change="fetchData()">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="guid:">
                                <el-input v-model="guid" @change="fetchData()">
                                </el-input>
                            </el-form-item>
                            <el-button size="small" type="primary" @click="fetchData()" round>Search</el-button>
                        </el-row>
                    </el-form>
                    <el-divider></el-divider>
                    <el-table :data="problems" style="width: 100%">
                        <el-table-column prop="client" label="账号">
                        </el-table-column>
                        <el-table-column label="题目">
                            <template slot-scope="scope">
                                <router-link :to="'/front/problem/detail?guid='+scope.row.guid"> {{scope.row.title}} </router-link>
                            </template>
                        </el-table-column>
                        <el-table-column prop="guid" label="GUID">
                        </el-table-column>
                        <el-table-column prop="update_time" label="更新时间">
                        </el-table-column>
                    </el-table>
                    <el-divider></el-divider>
                    <div class="text-center" style="margin-bottom: 20px">
                        <el-button size="medium" @click="toFirstPage" round>first</el-button>
                        <el-button size="medium" @click="toPrevPage" round>prev</el-button>
                        <el-button size="medium" @click="toNextPage" round>next</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getProblems } from '../../api/problem'

export default {
    data() {
        return {
            page: 1,
            client: '',
            title: '',
            guid: '',
            problems: []
        }
    },
    mounted() {
        // 获取数据
        if ('page' in this.$route.query) {
            this.page = this.$route.query.page
        }
        this.fetchData()
    },
    methods: {
        fetchData: function() {
            getProblems(this.page, this.client, this.title, this.guid).then(response => {
                this.problems = response.data.problem

                console.log(response.data)
            }).catch(error => {
                console.log('In problem list get problems error: ', error)
            })
        },
        toFirstPage() {
            this.page = 1
            this.fetchData()
        },
        toPrevPage: function() {
            this.page--
            if (this.page < 1) {
                this.page = 1
            }
            this.fetchData()
        },
        toNextPage() {
            this.page++
            this.fetchData()
        },
        changeLib() {
            this.pnid = ''
            this.fetchData()
        }
    }
}
</script>
<style scoped>
#problems {}
</style>