import request from '@/utils/request'

// 获取评测语言
export function getLanguages () {
    return request({
        url: '/server/languages',
        method: 'get',
        params:{

        }
    })
}

// 获取对外评测开关
export function getOpenJudge () {
    return request({
        url: '/server/get_open_judge',
        method: 'get',
        params:{

        }
    })
}

// 设置对外评测开关
export function setOpenJudge (open_judge) {
    return request({
        url: '/server/set_open_judge',
        method: 'post',
        data: {
            open_judge: open_judge
        }
    })
}