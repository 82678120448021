<template>
    <div id="footer">
        <div class="container">
            <div class="row clearfix">
                <div id="it" class="col-md-12 column">
                    <div>Developer: temle5 </div>
                    <div>mail: jwxu95@foxmail.com</div>
                    <div><a href="https://beian.miit.gov.cn/">闽ICP备17017903号-{{version}}</a></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                version : document.domain == "www.temle5.cn" ? 2 : 1
            }
        }
    }
</script>

<style scoped>
    #it{
        color: #985f0d;
        border: solid #2aabd2;
        border-width:1px;
    }
</style>
