import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})


service.interceptors.request.use(config => {
//  console.log(store.getters.token)
  if (getToken() != '') {
    config.headers['X-Token'] = getToken()
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

service.interceptors.response.use(response => {
  const res = response.data
  if (res.code === 1) { // 登录过期
    store.dispatch('FedLogOut').then(() => {
     // location.reload()
    })
    const error = {
      message: 'error'
    }
    return Promise.reject(error)
  } else {
    return Promise.resolve(response)
  }
}, error => {
  console.log('err' + error)
  return Promise.reject(error)
})

export default service
