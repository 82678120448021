<template>
  <div id="nav">
    <div class="container">
      <div class="row clearfix">
        <div class="col-md-12 column">
          <el-image :src="header_img_url"></el-image>
          
          <div class="col-md-12 column">
            <el-row :gutter="20">
              <el-menu mode="horizontal" :router="true">
                  <el-menu-item index="/front/queue">评测队列</el-menu-item>
                  <el-menu-item index="/front/judger">评测机</el-menu-item>
                  <el-menu-item index="/front/config">配置</el-menu-item>
                  <el-menu-item index="/front/problem">题目列表</el-menu-item>
                  <el-menu-item index="/front/default_problem">默认题目</el-menu-item >

                <el-col :span="4" :offset="10">
                  <el-submenu index="/front/login">
                    <template slot="title">登录</template>
                    <el-menu-item index="/front/login">Login</el-menu-item>
                    <el-menu-item index="/front/internal">Internal Api</el-menu-item>
                    <el-menu-item index="#">Logout</el-menu-item>
                  </el-submenu>
                </el-col>
              </el-menu>
            </el-row>
  <!--    
              <div class="form-inline" style="color: #843534;margin-top: 10px; margin-right:20px">
                  <vswitch class="pull-right" v-model="open_judge" text="on|off" ></vswitch>
                  <label class="control-label pull-right">对外测试：</label>
              </div>
  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    // import {getOpenJudge, setOpenJudge} from '../api/server'
  import img_url from '@/assets/header.jpg'
  export default {
      data () {
          return {
              open_judge: true,
              header_img_url: img_url
          }
      },
      mounted () {
          this.fetchData()
      },
      watch: {
          open_judge(val) {
              this.updateOpenJudge(val)
          }
      },
      methods: {
          fetchData() {
              /*
              getOpenJudge().then(response => {
                  let data = response.data
                  //console.log('get',data)
                  if (data.status == 'success') {
                      //console.log(data.open_judge)
                      if (data.open_judge == 'true' || data.open_judge == true)
                          this.open_judge = true
                      else
                          this.open_judge = false
                  }
              }).catch(e => {
                  console.log(e)
              })
              */
          },
          updateOpenJudge() {
              /*
              setOpenJudge(this.open_judge).then(response=>{
                  let data = response.data
                  //console.log(data)
                  if (data.status != 'success')
                      alert('update open judge switch error')
              }).catch(e=>{
                  console.log(e)
              })
              */
          }
      }
  }
</script>

<style scoped>
    .blogContent img {
        max-width: 100%;
    }
</style>
