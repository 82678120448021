import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getters'

Vue.use(Vuex)

const actions = { auth: { handler: () => {}, logout: () => sinon.stub() } };

export default new Vuex.Store({
  actions,
  modules: {
    user
  },
  getters
})
