<template>
    <div id="add_problems">
        <div class="container">
            <div class="row clearfix">

            <el-tabs type="border-card">
                <div class="col-md-12 column">
                    <div class="h2 text-info text-center">新增测试</div>
                    <div>

                        <el-form label-width="80px">
                            <el-form-item label="题目：">
                              <el-input v-model="title"/>
                            </el-form-item>

                            <el-form-item label="概述：">
                              <el-input v-model="abstract"/>
                            </el-form-item>

                            <el-form-item label="描述：">
                                <div class="col-md-1"></div>
                                <div id="t">
                                    <br/>

                                    <mavon-editor style="height:100%" v-model="description">
                                    </mavon-editor>

                                    <br/>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>

                <div class="col-md-12 column">
                    <div>
                        <el-row :gutter="20">
                            <el-form :inline="true">
                                <el-col :span="4">
                                    <el-form-item label="限制：">
                                    </el-form-item>
                                    <el-button type="primary" round @click="addLang()">新增</el-button>
                                </el-col>
                            </el-form>
                        </el-row>
                        
                        <div>
                            <el-form :inline="true" v-for="(item, index) in langs">
                                <el-form-item label="compiler:">
                                    <el-select v-model="item.lang" @change="changeOption()">
                                        <el-option
                                          v-for="(item2) in options"
                                          :value="item2.value">
                                          {{item2.text}}
                                        </el-option>
                                    </el-select>   

                                    <el-form-item label="时间">
                                      <el-input v-model="item.time"> <slot slot="suffix" class="input-slot">ms</slot> </el-input>
                                    </el-form-item>

                                    <el-form-item label="内存">
                                      <el-input v-model="item.memory"><slot slot="suffix" class="input-slot">MB</slot> </el-input>
                                    </el-form-item>

                                    <el-button type="danger" round @click="delLang(index)">删除</el-button>
                                </el-form-item>
                            </el-form>
                        </div>


                        <br/>

                        <el-row :gutter="20">
                            <el-col>
                                 <el-button type="danger" round @click="submit()">提交</el-button>
                            </el-col>
                        </el-row>
                
                    </div>

                </div>

            </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    import {getLanguages} from '../../api/server'
    import {addProblem, uploadProblem} from '../../api/default_problem'
    export default {
        data () {
            return {
                title: 'a+b',
                guid: '',
                abstract: 'a+b 基本测试',
                description: `### 计算a+b，测试使用,

    给定10组数据，每组数据包含两个整数，输出要求两数之和`,
                opts: [
                ],
                languages: [
                ]
            }
        },
        computed: {
            options () {
                return this.opts
            },
            langs () {
                return this.languages
            }
        },
        mounted () {
            this.fetchData()
            if (this.options.length > 0 && this.langs.length == 0) {
                this.languages.push({
                    lang: this.options[0].value,
                    time: 1000,
                    memory: 32
                })
                this.opts[0].disable = true
            }
        },
        methods: {
            changeOption() {
                for (let i=0; i<this.options.length; i++)
                    this.options[i].disable = false
                for (let i=0; i<this.langs.length; i++) {
                   for (let j=0; j<this.options.length; j++)
                       if (this.langs[i].lang == this.options[j].value) {
                           this.options[j].disable = true
                           break
                       }
                }
            },
            addLang () {
                let index = -1
                for (let i = 0; i < this.options.length; i++) {
                    if (this.options[i].disable == false) {
                        index = i
                        break
                    }
                }
                if (index > -1) {
                    this.options[index].disable = true
                    this.langs.push({
                        lang: this.options[index].value,
                        time: 1000,
                        memory: 32
                    })
                 }
                else
                    alert('not more compiler')
            },
            delLang (index) {
                for (let i = 0; i < this.options.length; i++) {
                    if ( this.options[i].value == this.langs[index].lang ) {
                        this.options[i].disable = false
                    }
                }
                this.langs.splice(index, 1)
            },
            reset () {
                this.langs.splice(0, this.langs.length)
            },
            fetchData () {
                getLanguages().then( response =>{
                    let data = response.data
                    if (data.status == 'success') {
                        let languages = data.langs
                        for (let i=0; i<languages.length; i++) {
                            this.options.push({
                                text: languages[i].language,
                                value: languages[i].compiler,
                                disable: false
                            })
                        }
                    }
                }).catch(err => {
                    console.log(err)
                })
            },
            submit () {

                let formData = new FormData()
                formData.append('problemTitle', this.title)
                formData.append('description', this.description)

                let restrict = {}
                restrict.validate = true
                restrict.langs = []

                for (let i=0; i< this.langs.length; i++) {
                    restrict.langs.push({
                        language: this.langs[i].lang,
                        time_limit: this.langs[i].time,
                        memory_limit: this.langs[i].memory * 1024
                    })
                }

                formData.append('restrict', JSON.stringify(restrict))

                uploadProblem(formData).then(res=>{
                    let data0 = res.data
                    console.log(data0)
                    if (data0.status == 'success') {
                        this.guid = data0.GUID
                        addProblem(this.title, this.abstract, this.description, this.langs, this.guid).then(response=>{
                            let data = response.data
                            if (data.status != 'success' ) {
                                console.log(data.info)
                            }
                            else {
                                alert('add problem success')
                                this.$router.push({path: '/front/default_problem'})
                            }
                        }).catch(e=>{
                            console.log(e)
                        })
                    }
                    else {
                        alert('upload file error')
                    }
                })

            }
        }
    }
</script>
<style scoped>
</style>
