import request from '@/utils/request'

// 上传题目
export function uploadProblem (formData) {
    return request({
        url: '/upload_file',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData
    })
}

// 上传题目
export function addProblem (title, abstract, description, langs, guid) {
    return request({
        url: '/server/default_problem/add',
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            title: title,
            abstract: abstract,
            description: description,
            langs: langs,
            guid: guid
        }
    })
}

// 更新题目
export function updateProblem(id, guid, title, abstract, description, langs, enable) {
    return request({
        url: '/server/default_problem/update',
        method: 'post',
        data: {
            id: id,
            guid: guid,
            title: title,
            abstract: abstract,
            description: description,
            langs: langs,
            enable: enable
        }
    })
}

// 删除题目
export function deleteProblem(id) {
    return request({
        url: '/server/default_problem/delete',
        method: 'post',
        data: {
            id: id
        }
    })
}

// 获取题目列表
export function getProblemList (page, title) {
    return request({
        url: '/server/default_problem/problems',
        method: 'get',
        params: {
            page: page,
            title: title
        }
    })
}

// 获取题目详情
export function getProblemDetail (id) {
    return request({
        url: '/server/default_problem/detail',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function getDataList(id) {
    return request({
        url: '/server/problem/data/list',
        method: 'get',
        params: {
            guid: id
        }
    })
}

export function updateTestCode(id, op, data) {
    return request({
        url: "/server/default_problem/update_test_code",
        method: "post",
        data: {
            id: id,
            op: op,
            data: data
        }
    })
}
