<template>
  <div id="login">
    <div class="container">
      <el-row :gutter="20">
          <el-divider/>
          <el-col :span="14" :offset="6">
            <el-form :model="loginForm" label-width="80px">
              <el-form-item label="User:">
                <el-input
                  v-model="loginForm.username">
                </el-input>
              </el-form-item>
              <el-form-item label="Password:">
                <el-input
                  v-model="loginForm.password"
                  @change="handleLogin()"
                  show-password>
                </el-input>
              </el-form-item>
              <el-button plain @click="handleLogin()">Submit</el-button>
            </el-form>
          </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
      data () {
        return {
          loginForm: {
            username: '',
            password: ''
          }
        }
      },
      computed: {
        ...mapGetters({
          auth: 'Auth'
        })
      },
      methods: {
        handleLogin () {
          let _this = this
          this.$store.dispatch('LoginByUserName', this.loginForm).then(response => {
            _this.$router.back()
            console.log(response.status)
          }).catch(err => {
            console.log('loging ', err)
          })
        },
        check () {
          // check for username
          return true
        }
      }
    }
</script>

<style scoped>
  .container {
  }
  #form1 {
    border: solid #985f0d;
  }
  #t1{
  }
  #t2{
    margin-top: 20px;
    margin-bottom: 20px;
  }
</style>
