<template>
  <div id="app" ref="output3">
<!--
    <div id="exportPDF" class="text-right" style="margin-right: 20px">
        <button id="exportButton" class="btn btn-warning" @click="saveImage('output3', 'App')">生成PDF</button>
    </div>
-->
    <div class="container">
      <div class="row clearfix" id="output2" ref="output2">
        <div class="col-md-12 column">
          <nav-view></nav-view>
          <router-view></router-view>
          <footer-view></footer-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NavView from './components/Nav'
  import FooterView from './components/Footer'
  import html2canvas from 'html2canvas'
  import jsPDF from 'jspdf'

  export default {
    name: 'App',
    components: {
        NavView,
        FooterView
    },
    methods: {
       saveImage(divText) {
            let canvasID = this.$refs[divText];
            let exportButten = document.getElementById("exportPDF")
            exportButten.style.display = "none"
            html2canvas(canvasID, { background:'#EFFBEF' }).then(canvas => {
                let contentWidth = canvas.width
                let contentHeight = canvas.height
                let pageHeight = contentWidth / 592.28 * 841.89
                let leftHeight = contentHeight
                let position = 0
                let imgWidth = 595.28
                let imgHeight = 592.28 / contentWidth * contentHeight
                let pageData = canvas.toDataURL('image/jpeg', 1.0)
                exportButten.style.display = "block"
                
                let PDF = new jsPDF('', 'pt', 'a4')
                if (leftHeight < pageHeight) {
                  PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                } else {
                  while (leftHeight > 0) {
                    PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                    leftHeight -= pageHeight
                    position -= 841.89
                    if (leftHeight > 0) {
                      PDF.addPage()
                    }
                  }
                }
                PDF.save("example.pdf")
            }).catch (e=> {
                exportButten.style.display = "block"
                console.log(e)
            });
        }
    }
  }
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  background-color: #EFFBEF;
}

</style>
