import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as custom from "./filters/custom.js";
import comps from "./components/comps/index.js";
import "../node_modules/mavon-editor/dist/css/index.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import MavonEditor  from "mavon-editor";
import ElementUI from "element-ui";
import "../node_modules/element-ui/lib/theme-chalk/index.css";
import FileUpload from "vue-simple-uploader";
import VueCodemirror from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/clike/clike.js';
import 'codemirror/theme/midnight.css';
import 'codemirror/theme/eclipse.css';


Vue.config.productionTip = false;
Vue.use(comps);
Vue.use(MavonEditor);
Vue.use(ElementUI);
Vue.use(FileUpload);
Vue.use(VueCodemirror);

Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key]);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
