
import Vswitch from './Vswitch'

function plugin(Vue) {
    if (plugin.installed) {
        return ;
    }
    Vue.component(
        'vswitch', Vswitch
    )
}

export default plugin