import request from '@/utils/request'

export function loginByUsername (username, password) {
  return request({
    url: '/auth',
    method: 'get',
    params: {
        user: Buffer.from(username, 'utf-8').toString('base64'),
        passwd: Buffer.from(password, 'utf-8').toString('base64')
    }
  })
}

export function logout () {
  return request({
    url: '/auth/logout',
    method: 'post'
  })
}

export function getUserInfo (user) {
  return request({
    url: '/user/info',
    method: 'get',
    params: {
      user: user
    }
  })
}

export function registerIf (user) {
  return request({
    url: '/auth/registerIf',
    method: 'get',
    params: {
      user: user
    }
  })
}

export function register (user) {
  return request({
    url: '/auth/register',
    method: 'post',
    data:user
  })
}