import request from '@/utils/request'

export function getConfigList () {
  return request({
    url: '/server/config/list',
    method: 'get'
  })
}


export function updateConfig (data) {
  return request({
    url: '/server/config/update',
    method: 'post',
    data: {
        data: data
    }
  })
}

export function getCompilerDetail (compiler) {
  return request({
    url: '/server/config/detail',
    method: 'get',
    params: {
        compiler: compiler
    }
  })
}

export function getCompilerList () {
  return request({
    url: '/server/config/list',
    method: 'get',
    params: {
    }
  })
}

export function operate_compiler_api (compiler, op) {
  return request({
    url: '/server/config/operate',
    method: 'post',
    data: {
        compiler: compiler,
        operation: op
    }
  })
}