<template>
    <div id="default_problems">
        <div class="container">
            <div class="row clearfix">
                <div class="col-md-12 column">
                    <h2 class="hd-title">default problems</h2>
                    <el-form size="small" :inline="true" @submit.native.prevent>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="题目:">
                                    <el-input v-model="title" @change="fetchData()" />
                                </el-form-item>
                                <el-button size="small" type="primary" @click="fetchData()" round>搜索</el-button>
                            </el-col>
                            <el-col :span="6" :offset="6">
                                <el-button size="small" type="warning" icon="el-icon-document-add" @click="toAddDproblem()" round>新增</el-button>
                            </el-col>
                        </el-row>
                    </el-form>
                    <el-divider></el-divider>
                    <el-table :data="problems" style="width: 100%">
                        <el-table-column label="激活" align="center">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.enable" @change="set_default_problem(scope.row.id, scope.row.enable)">
                                </el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column prop="container" label="题目" width="180">
                            <template slot-scope="scope">
                                <router-link :to="'/front/default_problem/detail?id='+scope.row.id"> {{scope.row.title}} </router-link>
                            </template>
                        </el-table-column>
                        <el-table-column prop="abstract" width="400" label="概述">
                        </el-table-column>
                        <el-table-column prop="update_time" label="更新时间">
                        </el-table-column>
                    </el-table>
                    <el-divider></el-divider>
                    <div class="text-center" style="margin-bottom: 20px">
                        <el-button size="medium" @click="toFirstPage" round>first</el-button>
                        <el-button size="medium" @click="toPrevPage" round>prev</el-button>
                        <el-button size="medium" @click="toNextPage" round>next</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getProblemList, updateProblem } from '../../api/default_problem'

export default {
    data() {
        return {
            page: 1,
            client: '',
            title: '',
            guid: '',
            problems: []
        }
    },
    mounted() {
        // 获取数据
        if ('page' in this.$route.query) {
            this.page = this.$route.query.page
        }
        this.fetchData()
    },
    methods: {
        fetchData() {
            getProblemList(this.page, this.title).then(response => {
                let data = response.data
                if (data.status == 'success') {
                    let problems = data.problems
                    for (let i=0; i<data.problems.length; i++) {
                        problems[i].enable = problems[i].enable == 1 ? true : false
                    }
                    this.problems = data.problems
                }
            }).catch(error => {
                console.log('In problem list get problems error: ', error)
            })
        },
        toFirstPage() {
            this.page = 1
            this.fetchData()
        },
        toPrevPage: function() {
            this.page--
            if (this.page < 1) {
                this.page = 1
            }
            this.fetchData()
        },
        toNextPage() {
            this.page++
            this.fetchData()
        },
        changeLib() {
            this.pnid = ''
            this.fetchData()
        },
        toAddDproblem() {
            this.$router.push('/front/add_problem')
        },
        set_default_problem(id, enable) {
            console.log(id, enable)
            updateProblem(id, '', '', '', '', '', enable == true? 1: 0, '').then(resp=>{}).catch(e=>{})
        }
    }
}
</script>
<style scoped>
#problems {}
</style>