<template>
    <div id="submission-result">
        <div class="container">
            <div class="row clearfix">
                <div class="col-md-12">
                    <div class="page-header">
                        <h3>详细</h3>
                    </div>
                    <div>
                        <blockquote style="text-align:left">
                            <p class="text-center" v-text="'题目 '+title"></p>
                            <p>简介</p>
                            <mavon-editor style="height:100%" 
                                :value="description" 
                                defaultOpen="preview" 
                                :toolbarsFlag="false"
                                :subfield="false"
                                :scrollStyle="false"
                                :editable="false">
                            </mavon-editor>
                            <p>更新时间</p>
                            <pre>{{update_time}}</pre>
                        </blockquote>
                    </div>

                    <div>
                        <p style="text-align:left"> 评测限制 </p>
                        <el-form :inline="true" v-for="(item, index) in langs" :key="item.language">
                            <el-form-item>
                                <el-form-item label="语言：">
                                    <el-input :value="item.language" :disabled="true" />
                                </el-form-item>

                                <el-form-item label="时间">
                                  <el-input :value="item.time_limit" :disabled="true"> <slot slot="suffix" class="input-slot">ms</slot> </el-input>
                                </el-form-item>

                                <el-form-item label="内存">
                                  <el-input :value="item.memory_limit/1024" :disabled="true"><slot slot="suffix" class="input-slot">MB</slot> </el-input>
                                </el-form-item>
                            </el-form-item>
                        </el-form>
                    </div>

                    <br/>

                    <div class="col-md-1"></div>
                    <div id="t" class="col-md-12 text-center">

                        <el-row :gutter="20">
                            <el-form :inline="true">
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="guid:">
                                          <el-input
                                            :disabled="true"
                                            v-model="guid"
                                            change="fetchData()"/>
                                        </el-form-item>
                                        <el-form-item label="测试次数:">
                                          <el-input
                                            v-model="test_times"
                                            change="fetchData()">
                                          </el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="6" :offset="6">
                                        <el-form-item label="language:">
                                            <el-select v-model="language" placeholder="编译器">
                                                <el-option
                                                  v-for="item in options"
                                                  :key="item.value"
                                                  :label="item.text"
                                                  :value="item.value"
                                                  :disabled="item.disabled">
                                                </el-option>
                                            </el-select>   
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </el-row>

                        <br/>

                        <codemirror ref="myCm"
                                   v-model="code"
                                   class="code"
                                  :options="cmOptions">
                        </codemirror>

                        <br/>
                        <div class="col-md-12 column">
                            <el-button plain @click="submitSolution">Submit</el-button>
                            <el-button plain @click="resetCode()">Reset</el-button>
                        </div>

                    </div>
                    <div class="col-md-1"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getProblemDetail, submitAnswer} from '../../api/problem'
    import {getLanguages} from  '../../api/server'

    export default {
        data () {
            return {
                title: "a+b",
                guid: '',
                description: 'loading',
                update_time: 'loading',
                test_num: 0,
                test_times: 1,
                code: '',
                langs: [],
                cmOptions: {
                    // codemirror options
                    indentWithTabs: true,
                    smartIndent: true,
                    mode: 'text/x-c++src',
                    theme: 'eclipse',
                    lineNumbers: true,
                    line: true,
                    autoRefresh: true,
                    // more codemirror options, 更多 codemirror 的高级配置...
                  },
                language: '',
                opts: [
                ]
            }
        },
        computed: {
            options () {
                return this.opts
            }
        },
        mounted () {
            if ('guid' in this.$route.query) {
                this.guid = this.$route.query.guid
            }
            this.fetchData()
        },
        methods: {
            fetchData () {
                getLanguages().then( response =>{
                    let data = response.data
                    if (data.status == 'success') {
                        let languages = data.langs
                        for (let i=0; i<languages.length; i++) {
                            this.options.push({
                                text: languages[i].language,
                                value: languages[i].compiler
                            })
                        }
                    }
                }).catch(err => {
                    console.log(err)
                })

                getProblemDetail(this.guid).then(response => {
                    let data = response.data
                    console.log(data)
                    this.title = data.problem.title
                    this.description = data.problem.description
                    this.update_time = data.problem.update_time
                    this.test_num = data.problem.test_num

                    let info = JSON.parse(data.problem.info)
                    this.langs = info.langs
                    console.log(this.langs)
                })
            },
            submitSolution () {
                submitAnswer(this.guid, this.title, this.language, this.code, this.test_times).then( response=>{
                    let data =response.data
                    console.log(data)
                    if (data.status == 'success') {
                        alert('submit test success')
                        this.$router.push('/front/queue')
                    }
                }).catch(e=>{
                    console.log(e)
                })
            },
            resetCode () {
                this.code = ''
            }
        }
    }
</script>

<style scoped>
    th{
        text-align: center;
        border: solid #2aabd2;
    }
    pre {
      background: white;
    }

    .code {
        text-align: left;
    }
</style>