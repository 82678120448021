<template>
    <div id="statistics">
        <div class="container">
            <div class="row clearfix">
                <div class="col-md-12">
                    <div class="page-header">
                        <h3>Statistics</h3>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div>
                                <div class="col-md-6 form-group pull-left">
                                    <label for="lang">评测结果:</label>
                                        <el-select id="status" 
                                            multiple
                                            @change="fetchData()"
                                            v-model="choose_status">
                                            <el-option v-for="item in status_opts"
                                                :key="item.text"
                                                :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                </div>

                                <div clas="col-md-4">
                                    <div class="form-group">
                                        <div class="form-inline">
                                            <label> 提交时间： </label>
                                            <el-date-picker
                                                v-model="query_time"
                                                type="datetimerange"
                                                align="right"
                                                unlink-panels
                                                value-format="timestamp"
                                                range-separator="至"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期"
                                                @change="fetchData()"
                                                :picker-options="pickerOptions">
                                            </el-date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <button class="btn-info"  style="float:right" @click="fetchData()">Search</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <hr/>
                    </div>

                    <div class="col-md-6">
                        <div class="col-md-6">
                            <blockquote class="text-left"> 提交概览 </blockquote>
                        </div>
                        <div id="result_pie" class="col-md-6" :style="{width: '100%', height: '500px'}">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-6">
                            <blockquote class="text-left"> 统计数据 </blockquote>
                        </div>
                        <div id="result_column" class="col-md-6" :style="{width: '100%', height: '500px'}">
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="col-md-12">
                            <blockquote class="text-left"> 评测历史记录 </blockquote>
                        </div>
                        <div class="col-md-9"> 
                            <div id="result_line"  :style="{width: '100%', height: '530px', top:'5px', bottom: '5px'}">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="col-md-12" style="height: 160px">
                            </div>
                            <div class="col-md-12"> 
                                <p style=" line-height:25px">
                                近期提交中，通过的提交记录占比提升到50%，错误答案呈现上升趋势。
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="col-md-12" style="top:5px">
                            <blockquote class="text-left"> 题目评测成功统计 </blockquote>
                        </div>

                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                            <tr>
                                <th> # </th>
                                <th class="text-center">题目ID</th>
                                <th class="text-center">题目</th>
                                <th class="text-center">提交数</th>
                                <th class="text-center">通过率</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr class="text-center" v-for="(item,index) in result_problem_status ">
                                    <td> {{index}} </td>
                                    <td> {{item.guid}} </td>
                                    <td> {{item.problem_title}} </td>
                                    <td> 
                                        <a style="color: red" href="/queue/undefined">
                                            {{item.accept}}
                                        </a>/{{item.total}}
                                    </td>
                                    <td> {{(item.accept/item.total*100).toFixed(2)}}% </td>
                                </tr>
                            </tbody>
                          </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getSubmissionResultSet, getSubmissionTrend, getProblemStatus} from '../../api/queue'
    import * as echarts from 'echarts';

    var status_color = {
        ["Accepted"] : 'red',
        ["Runtime Error"]: 'purple',
        ["Wrong Answer"] : 'green',
        ["Memory Limit Execeed"]: "blue",
        ["Invalid Memory Reference"]: "#66FFFF"
    }

    export default {
        data () {
            return {
                run_id: 0,
                choose_status: [],
                status_opts: [
                        { text: "AC", label: "AC", value: "Accepted" },
                        { text: "CE", label: "CE", value: "Compile Error" },
                        { text: "RE", label: "RE", value: "Runtime Error" },
                        { text: "WA", label: "WA", value: "Wrong Answer" },
                        { text: "MLE", label: "MLE", value: "Memory Limit Execeed" },
                        { text: "IMR", label: "IMR", value: "Invalid Memory Reference" }
                    ],
                compile: '',
                update_time: 'loading',
                code: 'loading',
                result_data_option: {},
                result_data_column: {},
                result_data_line: {},
                resultData: [],
                result_problem_status: [],
                query_time: "",
                pickerOptions: {
                  shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                      const end = new Date();
                      const start = new Date();
                      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                      picker.$emit('pick', [start, end]);
                    }
                  }, {
                    text: '最近一个月',
                    onClick(picker) {
                      const end = new Date();
                      const start = new Date();
                      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                      picker.$emit('pick', [start, end]);
                    }
                  }, {
                    text: '最近三个月',
                    onClick(picker) {
                      const end = new Date();
                      const start = new Date();
                      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                      picker.$emit('pick', [start, end]);
                    }
                  },{
                    text: '最近一年',
                    onClick(picker) {
                      const end = new Date();
                      const start = new Date();
                      start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                      picker.$emit('pick', [start, end]);
                    }
                  }]
                }
            }
        },
        watch: {
            result_data_column(val) {
                this.result_data_column = val
                this.result_column.setOption(this.result_data_column)
            },
            result_data_option(val) {
                this.result_data_option = val
                this.result_pie_Chart.setOption(this.result_data_option)
            },
            result_data_line(val) {
                this.result_data_line = val
                this.result_line_chart.setOption(this.result_data_line)
            }
        }, 
        mounted () {

            if ('run_id' in this.$route.query) {
                this.run_id = this.$route.query.run_id
            }
            this.fetchData()

            this.result_pie_Chart = echarts.init(document.getElementById("result_pie"))
            this.result_pie_Chart.setOption(this.result_data_option)

            this.result_column = echarts.init(document.getElementById("result_column"))
            this.result_column.setOption(this.result_data_column)

            this.result_line_chart = echarts.init(document.getElementById("result_line"))
            // this.result_line_chart.setOption(this.result_data_line)
        },
        methods: {
            fetchData () {
                // console.log(this.choose_status, this.query_time)
                getSubmissionResultSet(this.choose_status, this.query_time).then(response => {
                    var data = response.data
                    var result = data.result

                    var xAxis = {
                         type: 'category',
                         boundaryGap:[0, 0.01],
                         axisLabel: {
                            interval: 0,
                            rotate: -30
                         }, 
                         data: []
                    }
                    var series = {}
                    series.type = 'bar'
                    series.data = []

                    var pie_data = []
                    for (var i=0; i<result.length; i++) {
                        var color = status_color[result[i].STATUS]
                        series.data.push({
                            value: result[i].num,
                            itemStyle: {
                                color: color
                            }
                        })
                        xAxis.data.push(result[i].STATUS)

                        pie_data.push({
                            value: parseInt(result[i].num),
                            name: result[i].STATUS,
                            itemStyle: {
                                color: color
                            }
                        })
                    }

                    this.result_data_column = {
                        xAxis: xAxis,
                        yAxis:  {
                            type: 'value',
                            axisLabel: {
                                interval: 0,
                                rotate: -60
                            }
                        },
                        series : [series]
                    }


                    this.result_data_option = {
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            top: '5%',
                            left: 'center'
                        },
                        series: [
                            {
                                name: '评测结果',
                                type: 'pie',
                                radius: ['40%', '70%'],
                                avoidLabelOverlap: false,
                                label: {
                                    show: true,
                                    position: 'center'
                                },
                                emphasis: {
                                    label: {
                                        show: true,
                                        fontSize: '40',
                                        fontWeight: 'bold'
                                    }
                                },
                                labelLine: {
                                    show: true
                                },
                                data: pie_data
                            }
                        ]
                    }
                }).catch(e=>{
                    console.log(e)
                })

                getSubmissionTrend(this.choose_status, this.query_time).then(response => {
                    var data = response.data
                    var result = data.result

                    var line_data = []
                    for (var i=0; i<result.length; i++) {
                        line_data.push({
                            name: result[i][0],
                            type: 'line',
                            data: result[i][1],
                            lineStyle: {
                                color: status_color[result[i][0]]
                            }
                        })
                    }


                    this.result_data_line = {
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: data.status_set
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        toolbox: {
                            feature: {
                                saveAsImage: {}
                            }
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: true,
                            data: data.time_section
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: line_data
                    }
                }).catch(e=> {
                    console.log(e)
                })

                getProblemStatus(this.query_time).then(response=> {
                    var data = response.data
                    console.log(data)
                    this.result_problem_status = data.result
                }).catch(err=>{
                    console.log(err)
                })
            }
        }
    }
</script>

<style scoped>
    th{
        text-align: center;
        border: solid #2aabd2;
    }
</style>
