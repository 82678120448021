<template>
    <div id="queue-list">
        <div class="container">
            <div class="row clearfix">
                <div class="col-md-12">
                    <h2 class="hd-title">评测队列</h2>
                    <div class="row">
                        <el-form :inline="true" size="small">
                            <el-row>
                                <el-form-item label="序号:">
                                    <el-input v-model="run_id" @change="fetchData()" />
                                </el-form-item>
                                <el-form-item label="账号:">
                                    <el-input v-model="client" @change="fetchData()">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="题目:">
                                    <el-input v-model="problem_title" @change="fetchData()" />
                                </el-form-item>
                            </el-row>
                        </el-form>
                        <el-form :inline="true" size="small">
                            <el-row>
                                <el-form-item label="语言:">
                                    <el-input v-model="language" @change="fetchData()" />
                                </el-form-item>
                                <el-form-item label="状态:">
                                    <el-input v-model="status" @change="fetchData()" />
                                </el-form-item>
                                <el-form-item label="比较:">
                                    <el-input v-model="judge_diff" @change="fetchData()" />
                                </el-form-item>
                            </el-row>
                        </el-form>
                        <div class="col-md-12">
                            <el-button size="small" type="primary" @click="fetchData()" round>Search</el-button>
                            <div class="pull-right" v-show="false">
                                <a href="/queue/statistics"> 提交统计 </a>
                            </div>
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <el-table :data="queue" style="width: 100%">
                        <el-table-column prop="run_id" label="序号">
                        </el-table-column>
                        <el-table-column prop="client" label="账号">
                        </el-table-column>
                        <el-table-column label="主机">
                            <template slot-scope="scope">
                                <div v-if="scope.row.host == ''">
                                    None
                                </div>
                                <div v-else>
                                    {{scope.row.host}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="problem_title" label="题目">
                        </el-table-column>
                        <el-table-column prop="submit_time" width="200" label="提交时间" align="center">
                        </el-table-column>
                        <el-table-column prop="status" width="150" align="center" label="状态">
                            <template slot-scope="scope">
                                <div class="span" v-if="true">
                                    <router-link :to="'/front/queue/result?run_id='+scope.row.run_id" :style="{ color: colorText(scope.row.status)}">
                                        {{scope.row.status}}
                                    </router-link>
                                </div>
                                <div class="span" :style="{ color: colorText(scope.row.status)}" v-else>
                                    {{scope.row.status}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="language" label="语言">
                        </el-table-column>
                        <el-table-column prop="judge_diff" label="版本对比" align="center">
                        </el-table-column>
                        <el-table-column prop="judger" width="200" align="center" label="评测机">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button @click=rejudge(scope.row.run_id) type="text" size="small">
                                    重测
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <el-divider></el-divider>
                    <div class="text-center" style="margin-bottom: 20px">
                        <el-button size="medium" @click="toFirstPage" round>first</el-button>
                        <el-button size="medium" @click="toPrevPage" round>prev</el-button>
                        <el-button size="medium" @click="toNextPage" round>next</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getQueue, rejudge } from '../../api/queue.js'
export default {
    name: "queue-list",
    data() {
        return {
            page: 1,
            timer: null,
            run_id: '',
            client: '',
            problem_title: '',
            language: '',
            status: '',
            judge_diff: '',
            task: '',
            queue: []
        }
    },
    mounted() {
        if ('task' in this.$route.query) {
            this.task = this.$route.query.task
        }
        this.fetchData()
        this.timer = setInterval(() => {
           getQueue(this.page, this.run_id, this.client, this.problem_title, this.language, this.status, this.judge_diff, this.task).then(resp=>{
                let data = resp.data
                if (data.status == 'success') {
                    this.queue = data.queue
                    for (let i = 0; i < this.queue.length; i++)
                        if (this.queue[i].status == 'Running')
                            this.queue[i].status = 'run case ' + this.queue[i].run_case
                } else {
                    console.log("close interval")
                    clearInterval(this.timer)
                    this.timer = null
                }
           }).catch(e=>{
                console.log(e)
                clearInterval(this.timer)
                this.timer = null
           })
        }, 5000)
    },
    beforeRouteLeave(to, from, next) {
        next();
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
    },
    methods: {
        test(id) {
            console.log(id)
        },
        fetchData() {
            getQueue(this.page, this.run_id, this.client, this.problem_title, this.language, this.status, this.judge_diff, this.task).then(response => {
                let data = response.data
                if (data.status == 'success') {
                    this.queue = data.queue
                    for (let i = 0; i < this.queue.length; i++)
                        if (this.queue[i].status == 'Running')
                            this.queue[i].status = 'run case ' + this.queue[i].run_case
                }
            })
        },
        toFirstPage() {
            this.page = 1
            this.fetchData()
        },
        toPrevPage: function() {
            this.page--
            if (this.page < 1) {
                this.page = 1
            }
            this.fetchData()
        },
        toNextPage() {
            this.page++
            this.fetchData()
        },
        colorText: function(result) {
            if (result === 'Accepted') return 'red'
            if (result === 'Wrong Answer') return 'green'
            if (result === 'Runtime Error') return 'purple'
            if (result === 'Memory Limit Execeed') return 'blue'
            if (result === 'Invalid Memory Reference') return '#66FFFF'
            if (result === 'Compile Error') return 'green'
            if (result.indexOf('run case') == 0) return 'DarkMagenta'
        },
        rejudge(run_id) {
            rejudge(run_id).then(response => {
                let data = response.data
                console.log(data)
                this.fetchData()
            }).catch(e => {
                console.log(e)
            })
        }
    }
}
</script>
<style scoped>
</style>