<template>
    <div id="queue-list">
        <div class="container">
            <div class="row clearfix">
                <div class="col-md-12">
                    <h2 class="hd-title">评测机</h2>
                    <el-divider></el-divider>
                    <el-table :data="judger_list" style="width: 100%" :row-class-name="tableRowClassName">
                        <el-table-column label="启用" align="center" width="80">
                            <template slot-scope="scope">
                                <span v-if="scope.row.enable == 0">
                                    <el-tag type="danger"> 未启用 </el-tag>
                                </span>
                                <span v-else>
                                    <el-tag type="success">已启用</el-tag>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="hostname" label="宿主机" align="center" width="180">
                        </el-table-column>
                        <el-table-column prop="container" label="容器名" align="center">
                        </el-table-column>
                        <el-table-column prop="user" label="账号" width="200" align="center">
                        </el-table-column>
                        <el-table-column label="状态" align="center" width="150">
                            <template slot-scope="scope">
                                <el-tag :type="scope.row.status_type"> {{scope.row.status}} </el-tag>
                                <!--<div :style="{ color: scope.row.status_color}"> {{scope.row.status}}</div> -->
                            </template>
                        </el-table-column>
                        <el-table-column label="用 例 数 据" align="center" width="90">
                            <el-table-column label="ac/fail/all" align="center">
                                <template slot-scope="scope">
                                    <span :style="{color: 'green'}"> {{scope.row.accept}} </span>/
                                    <span>
                                        <router-link :to="'/front/queue?task='+scope.row.task" :style="{color: 'red'}">
                                            {{scope.row.failed}}
                                        </router-link>
                                    </span>
                                    <span>/ {{scope.row.total}} </span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="log" label="日志上报" align="center">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.log" @change="operate_judger(scope.row.hostname, scope.row.container, scope.row.user, scope.row.log == true?'collect_log':'close_log')">
                                </el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column prop="update_time" align="center" label="更新时间" width="100">
                            <template slot-scope="scope">
                                <span> {{fromStrToCNTime(scope.row.update_time)}} </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="180">
                            <template slot-scope="scope">
                                <el-button @click="operate_judger(scope.row.hostname, scope.row.container, scope.row.user, 'remove')" type="text" size="small">
                                    移除
                                </el-button>
                                <el-button @click="operate_judger(scope.row.hostname, scope.row.container, scope.row.user, 'offline')" type="text" size="small">
                                    下线
                                </el-button>
                                <el-button @click="operate_judger(scope.row.hostname, scope.row.container, scope.row.user, 'online')" type="text" size="small">
                                    上线
                                </el-button>
                                <el-button @click="operate_judger(scope.row.hostname, scope.row.container, scope.row.user, 'reboot')" type="text" size="small">
                                    重启
                                </el-button>
                                <el-button @click="operate_judger(scope.row.hostname, scope.row.container, scope.row.user, 'set_test')" type="text" size="small">
                                    用例测试
                                </el-button>
                                <el-button @click="operate_judger(scope.row.hostname, scope.row.container, scope.row.user, 'force_update')" :style="{color:'red'}" type="text" size="small">
                                    强制更新
                                </el-button>
                                <el-popover placement="top" width="800" trigger="click">
                                    <el-row :gutter="20">
                                        <el-form :inline="true">
                                            <el-col :span="10" :offset="10">
                                                <el-button type="primary" size="small" round @click="operate_judger(scope.row.hostname, scope.row.container, scope.row.user, 'get_logs')">refesh</el-button>
                                                <el-button type="primary" size="small" round @click="operate_judger(scope.row.hostname, scope.row.container, scope.row.user, 'get_logs_next')">next page</el-button>
                                            </el-col>
                                        </el-form>
                                    </el-row>
                                    <el-table :data="log_data">
                                        <el-table-column width="180" property="create" label="上传时间">
                                            <template slot-scope="scope2">
                                                {{fromStrToCNTime(scope2.row.create)}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column width="400" property="info" label="日志">
                                            <template slot-scope="scope2">
                                                <pre>{{ scope2.row.info }}</pre>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <el-button slot="reference" @click="operate_judger(scope.row.hostname, scope.row.container, scope.row.user, 'get_logs')" type="text" size="small">日志查看</el-button>
                                </el-popover>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-divider></el-divider>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { get_judger_list, operate_judger_api } from '../../api/judger.js'
import { dateFormat } from '../../filters/custom.js'
export default {
    name: "queue-list",
    data() {
        return {
            page: 1,
            timer: null,
            judger_list: [],
            log_data: [],
            log_page: 0
        }
    },
    mounted() {
        this.fetchData()
        this.timer = setInterval(() => {
            get_judger_list().then(resp => {
                let data = resp.data
                if (data.status == 'success') {
                    this.judger_list = data.list
                } else {
                    console.log("close interval")
                    clearInterval(this.timer)
                    this.timer = null
                }
            }).catch(e => {
                console.log(e)
                clearInterval(this.timer)
                this.timer = null
            })
        }, 10000)
    },
    beforeRouteLeave(to, from, next) {
        next();
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
    },
    methods: {
        fetchData() {
            get_judger_list().then(response => {
                let data = response.data
                if (data.status == 'success') {
                    this.judger_list = data.list
                }
            })
        },
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex === 1) {
                return 'warning-row';
            } else if (rowIndex === 3) {
                return 'success-row';
            }
            return '';
        },
        fromStrToCNTime(str) {
            let date = str.substring(0, 19);
            date = date.replace(/-/g, '/'); //将'-'转化为'/'是因为有的手机上new Date('2020-09-06 00:00:00')会报错，所以统一转化为'2020/09/06 00:00:00'格式
            let time = new Date(date).getTime() + 8 * 60 * 60 * 1000; //考虑时区的差异，统一转化为东八区时间
            let cn_time_str = dateFormat(time)
            return cn_time_str;
        },
        update_judger_state(host, container, user, op) {
            operate_judger_api(host, container, user, op).then(resp => {
                console.log(resp.status)
                if (resp.status == 200) {
                    this.$message({
                        showClose: true,
                        message: '操作成功',
                        type: 'success'
                    })
                } else {
                    this.$message({
                        showClose: true,
                        message: '操作失败',
                        type: 'error'
                    })
                }
                this.fetchData()
            }).catch(e => {
                this.$message({
                    showClose: true,
                    message: '操作失败',
                    type: 'error'
                })
                console.log(e)
            })
        },
        operate_judger(host, container, user, op) {
            if (op == "force_update") {
                this.$confirm('此操作将强制删除已安装环境，并重新下载安装新编译环境，耗费时间较长, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.update_judger_state(host, container, user, op)
                }).catch(() => {
                    //点击删除按钮的操作
                })
            } else if (op == "get_logs" || op == "get_logs_next") {
                this.log_page = op == "get_logs" ? 0 : this.log_page + 1
                operate_judger_api(host, container, user, "get_logs", { page: this.log_page }).then(resp => {
                    let data = resp.data
                    if (data.status == 'success')
                        this.log_data = data.data
                }).catch(e => {
                    console.log(e)
                })
            } else {
                this.update_judger_state(host, container, user, op)
            }

        }
    }
}
</script>
<style scoped>
</style>