import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login'
import QueueList from '@/components/judging_queue/QueueList'
import SubmissionResult from '@/components/judging_queue/SubmissionResult'
import ProblemList from '@/components/problem/ProblemList'
import ProblemDetail from '@/components/problem/ProblemDetail'
import DefaultProblem from '@/components/default_problem/DefaultList'
import AddProblem from '@/components/default_problem/AddProblem'
import DefaultDetail from '@/components/default_problem/Detail'
import Statistics from '@/components/judging_queue/Statistics'
import Config from '@/components/config/Config'
import Judger from '@/components/judger/JudgerList'
import ConfigDetail from '@/components/config/ConfigDetail'

Vue.use(Router)

var {} = {

};
export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/front/queue',
            component: QueueList
        },
        {
            path: '/front/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/front/queue/result',
            component: SubmissionResult
        },
        {
            path: "/front/queue/statistics",
            component: Statistics
        },
        {
            path: '/front/problem',
            component: ProblemList
        },
        {
            path: '/front/problem/detail',
            component: ProblemDetail
        },
        {
            path: '/front/default_problem',
            component: DefaultProblem
        },
        {
            path: '/front/default_problem/detail',
            component: DefaultDetail
        },
        {
            path: '/front/add_problem',
            component: AddProblem
        },
        {
            path: '/front/config',
            component: Config
        },
        {
            path: '/front/config/detail',
            component: ConfigDetail
        },
        {
            path: '/front/judger',
            component: Judger
        }
    ]
})