import request from '@/utils/request'

// 上传题目
export function uploadProblem (formData) {
  return request({
    url: '/admin/upload_problem',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  })
}

export function rejudge (run_id) {
    return request({
        url: '/server/queue/rejudge',
        method: 'POST',
        data: {
            run_id:run_id
        }
    })
}

// 获取队列列表
export function getQueue (page, run_id, client, problem_title, language, status, diff, task) {
  return request({
    url: '/server/queue',
    method: 'get',
    params: {
      page: page,
        run_id: run_id,
        client: client,
        problem_title: problem_title,
        language: language,
        status: status,
        judge_diff: diff,
        task: task
    }
  })
}

// 获取评测结果
export function getSubmissionResult (run_id) {
    return request({
        url: '/server/queue/result',
        method: 'get',
        params: {
            run_id: run_id
        }
    })
}


// 获取最近评测结果集
export function getSubmissionResultSet (status_set, query_time) {
    return request({
        url: '/backend/queue/status_set',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            status_set: status_set,
            query_time: query_time
        }
    })
}

// 获取最近评测结果集
export function getSubmissionTrend (status_set, query_time) {
    return request({
        url: '/backend/queue/submit_trend',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            status_set: status_set,
            query_time: query_time
        }
    })
}

// 获取题目评测结果
export function getProblemStatus (query_time) {
    return request({
        url: '/backend/queue/problem_status',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            query_time: query_time
        }
    })
}