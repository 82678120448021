import * as types from '../mutation-types'
import {loginByUsername, logout, getUserInfo} from '../../api/login'
import * as auth from '../../utils/auth'

const user = {
  state: {
    uid: '',
    code: '',
    token: auth.getToken(),
    name: auth.getName(),
    roles: auth.getRoles()
  },
  mutations: {
    [types.SET_CODE]: (state, code) => {
      state.code = code
    },
    [types.SET_TOKEN]: (state, token) => {
      auth.setToken(token)
      state.token = token
    },
    [types.SET_NAME]: (state, name) => {
      auth.setName(name)
      state.name = name
    },
    [types.SET_ROLES]: (state, roles) => {
      auth.setRoles(roles)
      state.roles = roles
    }
  },
  actions: {
    LoginByUserName ({commit}, userInfo) {
      return new Promise((resolve, reject) => {
        loginByUsername(userInfo.username, userInfo.password).then(response => {
          const data = response.data
          commit(types.SET_NAME, userInfo.username)
          commit(types.SET_TOKEN, data.token)
          commit(types.SET_ROLES, [data.auth])
          console.log('/store/user:login success =>', data)
      //    setName(userInfo.username)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetUserInfo ({commit, state}) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(response => {
          if (!response.data) {
            const err = {message: 'getUserInfo error'}
            reject(err)
          }
          resolve(response)
        }).catch(err => {
          reject(err)
        })
      })
    },
    LogOut ({commit, state}) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit(types.SET_NAME, '')
          commit(types.SET_TOKEN, '')
          commit(types.SET_ROLES, [])
          auth.removeToken()
          auth.removeName()
          auth.removeRoles()
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 前端登出
    FedLogOut ({commit}) {
      return new Promise(resolve => {
        commit(types.SET_TOKEN, '')
        commit(types.SET_NAME, '')
        auth.removeToken()
        auth.removeName()
        auth.removeRoles()
        resolve()
      })
    },

    ChangeRoles ({commit}, token) {
      return new Promise(resolve => {
        commit(types.SET_TOKEN, token)
        getUserInfo(token).then(response => {
          const data = response.data
          commit(types.SET_NAME, data.name)
          commit(types.SET_ROLES, data.roles)
          resolve()
        })
      })
    }
  }
}
export default user
