import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const NameKey = 'UserName'
const RolesKey = 'Roles'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getName () {
  return Cookies.get(NameKey)
}

export function setName (name) {
  return Cookies.set(NameKey, name)
}

export function removeName () {
  return Cookies.remove(NameKey)
}

export function getRoles () {
  if (typeof Cookies.get(RolesKey) === 'undefined') {
    return []
  } else {
    return JSON.parse(Cookies.get(RolesKey))
  }
}

export function setRoles (roles) {
  return Cookies.set(RolesKey, JSON.stringify(roles))
}

export function removeRoles () {
  return Cookies.remove(RolesKey)
}
