<template>
    <div id="default-detail">
        <div class="container">
            <div class="row clearfix">
                <div class="col-md-12 column">
                    <br/>

                    <el-dialog
                      :title="get_title()"
                      :visible.sync="dialogVisible"
                      :close-on-click-modal="false"
                      :before-close="before_dialog_close"
                      width="30%">
                      <div>
                        <el-form label-width="80px">
                            <el-form-item label="编译器：">
                              <el-select v-model="tmp_compiler" placeholder="编译器">
                                    <el-option
                                      v-for="item in compiler_options"
                                      :key="item.value"
                                      :label="item.text"
                                      :value="item.value">
                                    </el-option>
                                </el-select>   
                            </el-form-item>

                            <el-form-item label="概述：">
                                <el-input v-model="tmp_abstract"/>
                            </el-form-item>

                            <mavon-editor v-model="tmp_code" :subfield="false" :toolbarsFlag="false"></mavon-editor>

                        </el-form>
                      </div>
                      <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="warning" @click="reset_dialog">重 置</el-button>
                        <el-button type="primary" @click="update_test_code()">{{get_title()}}</el-button>
                      </span>
                    </el-dialog>

                    <el-tabs v-model="activeName" @tab-click="change" type="border-card" id="tab-pane">
                        <el-tab-pane label="详情" name="1">
                            <div class="panel-heading">
                                <h3 class="panel-title">
                                    测试内容
                                </h3>
                            </div>
                            <div>
                                <el-form label-width="80px">
                                    <el-form-item label="题目：">
                                      <el-input v-model="title"/>
                                    </el-form-item>

                                    <el-form-item label="guid：">
                                      <el-input :value="getGuid()" :disabled="true"> </el-input>
                                    </el-form-item>

                                    <el-form-item label="概述：">
                                      <el-input v-model="abstract"/>
                                    </el-form-item>

                                    <el-form-item label="描述：">
                                        <div class="col-md-1"></div>
                                        <div id="t">
                                            <br/>

                                            <mavon-editor style="height:100%" v-model="description">
                                            </mavon-editor>

                                            <br/>
                                        </div>
                                    </el-form-item>

                                </el-form>

                                <div class="col-md-12 column">
                                    <div>
                                        <el-row :gutter="20">
                                            <el-form :inline="true">
                                                <el-col :span="4">
                                                    <el-form-item label="限制：">
                                                    </el-form-item>
                                                    <el-button type="primary" round @click="addLang()">新增</el-button>
                                                </el-col>
                                            </el-form>
                                        </el-row>
                                        
                                        <br/>
                                        <br/>
                                        <div>
                                            <el-form :inline="true" v-for="(item, index) in langs">
                                                <el-form-item label="compiler:">
                                                    <el-select v-model="item.lang" @change="changeOption()">
                                                        <el-option
                                                          v-for="(item2) in options"
                                                          :value="item2.value">
                                                          {{item2.text}}
                                                        </el-option>
                                                    </el-select>   

                                                    <el-form-item label="时间">
                                                      <el-input v-model="item.time"> <slot slot="suffix" class="input-slot">ms</slot> </el-input>
                                                    </el-form-item>

                                                    <el-form-item label="内存">
                                                      <el-input v-model="item.memory"><slot slot="suffix" class="input-slot">MB</slot> </el-input>
                                                    </el-form-item>

                                                    <el-button type="danger" round @click="delLang(index)">删除</el-button>
                                                </el-form-item>
                                            </el-form>
                                        </div>


                                        <br/>

                                        <el-row :gutter="20">
                                            <el-col :span="5">
                                                <el-switch
                                                    v-model="problem_data_upload"
                                                    active-text="更新数据"
                                                    inactive-text="不更新数据">
                                                </el-switch>
                                            </el-col>
                                            <br/>
                                            <div class="panel-body" v-show="problem_data_upload">
                                                <el-upload
                                                  action="/test"
                                                  multiple
                                                  :auto-upload="false"
                                                  :on-change="addFile"
                                                  :on-remove="rmFile"
                                                  list-type="text"
                                                  :file-list="fileList">
                                                  <el-button size="small" type="primary">点击上传</el-button>
                                                  <el-button size="small" type="success" @click="reset_files"> 重置数据 </el-button>
                                                </el-upload>
                                            </div>
                                        </el-row>

                                        <br/>

                                        <el-row :gutter="20">
                                            <el-col :span="5">
                                                <el-switch
                                                    v-model="enable"
                                                    active-text="激活题目"
                                                    inactive-text="关闭题目"
                                                    :active-value="true"
                                                    :inactive-value="false">
                                                </el-switch>
                                            </el-col>

                                            <el-col>
                                                 <el-button type="warning" round @click="deleteProblem()">删除</el-button>
                                                 <el-button type="danger" round @click="update()">修改</el-button>
                                            </el-col>
                                        </el-row>
                                
                                    </div>

                                </div>

                            </div>



                        </el-tab-pane>

                        <el-tab-pane label="标准程序" name="2">
                            <div class="panel-heading">
                                <h3 class="panel-title">
                                    测试代码
                                </h3>
                            </div>

                            <el-row :gutter="20">
                                <el-form :inline="true">
                                    <el-col :span="4" :offset="20">
                                        <el-button type="primary" size="small" round @click="add_new_test_code">新增</el-button>
                                    </el-col>
                                </el-form>
                            </el-row>

                            <el-table :data="test_codes" style="width: 100%">
                                <el-table-column prop="compiler" label="编译器" width="100">
                                </el-table-column>
                                <el-table-column prop="abstract" width="350" label="概述" align="center">
                                </el-table-column>
                                <el-table-column label="代码" width="500">
                                    <template slot-scope="scope">
                                        <el-popover
                                        placement="top-start"
                                        title="代码明细"
                                        width="500"
                                        trigger="hover"
                                        >
                                            <div><pre><code>{{scope.row.code}}</code></pre></div>
                                        <el-button slot="reference">{{scope.row.code}}</el-button>
                                      </el-popover>
                                        
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="200">
                                    <template slot-scope="scope">
                                        <el-button @click="update_test_code(scope.row.id, 'del')" type="text" size="small">
                                            删除
                                        </el-button>
                                        <el-button @click="edit_test_code(scope.row.id, scope.row.compiler, scope.row.abstract, scope.row.code)" type="text" size="small">编辑</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>

                        </el-tab-pane>
                    </el-tabs>
                    <br/>



                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getProblemDetail, updateProblem, deleteProblem, uploadProblem, getDataList, updateTestCode } from '../../api/default_problem'
    import {getLanguages} from '../../api/server'
    import { getToken } from '@/utils/auth'

    export default {
        data () {
            return {
                activeName: "1",
                title: "loading",
                id: 0,
                guid: '',
                abstract: 'loading',
                description: 'loading',
                dialogVisible: false,
                opts: [
                ],
                languages: [
                ],
                enable: null,
                enableOpts: [
                    {
                        value: 0,
                        text: '不激活'
                    },
                    {
                        value: 1,
                        text: '激活'
                    }
                ],
                problem_data_upload: false,
                fileList: [],
                nfileList: [],
                page: 1,

                test_codes: [],

                uploadReload: true,
                uploadOpts: {
                    target: process.env.VUE_APP_BASE_API ? process.env.VUE_APP_BASE_API + '/upload_file' : '/upload_file',
                    testChunks: false,
                    headers: {
                        'X-Token': getToken(),
                        'X-Guid': ''
                    }
                },

                tmp_compiler: '',
                tmp_abstract: '',
                tmp_code: '',
                tmp_id: '',
                compiler_options: []
            }
        },
        computed: {
            options () {
                return this.opts
            },
            langs () {
                return this.languages
            }
        },
        mounted () {
            if ('id' in this.$route.query) {
                this.id = this.$route.query.id
            }

            getLanguages().then( response =>{
                let data = response.data
                if (data.status == 'success') {
                    let languages = data.langs
                    for (let i=0; i<languages.length; i++) {
                        this.compiler_options.push({
                            text: languages[i].language,
                            value: languages[i].compiler
                        })
                    }
                }
            }).catch(err => {
                console.log(err)
            })
            this.fetchData()
        },
        methods: {
            fetchData () {

                getProblemDetail(this.id).then(response => {
                    let data = response.data
                    console.log(data)
                    if (data.status == 'success') {
                        this.title = data.problem.title
                        this.guid = data.problem.guid
                        if (this.guid == null )
                            this.guid = ''
                        this.abstract = data.problem.abstract
                        this.description = data.problem.description
                        this.languages = JSON.parse(data.problem.langs)

                        this.test_codes = data.problem.test_codes

                        if (data.problem.enable == 1)
                            this.enable = true
                        else
                            this.enable = false

                        getLanguages().then( res2 =>{
                            let data2 = res2.data
                            if (data2.status == 'success') {
                                let languages = data2.langs
                                for (let i=0; i<languages.length; i++) {
                                    this.options.push({
                                        text: languages[i].language,
                                        value: languages[i].compiler,
                                        disable: false
                                    })
                                }
                                for (let i=0; i < this.langs.length; i++) {
                                    for (let j=0; j<this.options.length; j++) {
                                        if (this.langs[i].lang == this.options[j].value) {
                                            this.options[j].disable = true
                                            break
                                        }
                                    }
                                }
                            }
                        }).catch(err => {
                            console.log(err)
                        })

                        getDataList(this.guid).then(response => {
                            let data = response.data
                            console.log(data)
                            if (data.status == 'success') {
                                this.fileList = data.list
                            }
                        }).catch(e => {
                            console.log(e)
                        })

                        this.uploadOpts.headers['X-Guid'] = this.guid
                        this.reload()
                    }
                }).catch(e=>{
                    console.log(e)
                })
            },
            change (){
                this.page = this.activeName
            },
            changeOption() {
                for (let i=0; i<this.options.length; i++)
                    this.options[i].disable = false
                for (let i=0; i<this.langs.length; i++) {
                    for (let j=0; j<this.options.length; j++)
                        if (this.langs[i].lang == this.options[j].value) {
                            this.options[j].disable = true
                            break
                        }
                }
            },
            addLang () {
                let index = -1
                for (let i = 0; i < this.options.length; i++) {
                    if (this.options[i].disable == false) {
                        index = i
                        break
                    }
                }
                if (index > -1) {
                    this.options[index].disable = true
                    this.langs.push({
                        lang: this.options[index].value,
                        time: 1000,
                        memory: 32
                    })
                }
                else
                    alert('not more compiler')
            },
            delLang (index) {
                for (let i = 0; i < this.options.length; i++) {
                    if ( this.options[i].value == this.langs[index].lang ) {
                        this.options[i].disable = false
                    }
                }
                this.langs.splice(index, 1)
            },

            rmFile(file) {
                console.log('del', file)
                for (let i=0; i<this.nfileList.length; i++) {
                    if (file.name == this.nfileList[i].name) {
                        this.nfileList.splice(i, 1)
                        break
                    }
                }
            },
            addFile(file) {
                this.nfileList.push(file)
            },
            reset_files() {
                this.fileList = []
                this.nfileList = []
            },
            update () {
                console.log(this.fileList)
                let enabled = 0
                if (this.enable == true)
                    enabled = 1

                let formData = new FormData()
                formData.append('problemTitle', this.title)
                formData.append('problemGUID', this.guid)
                formData.append('description', this.description)

                let restrict = {}
                restrict.validate = true
                restrict.langs = []

                for (let i=0; i< this.langs.length; i++) {
                    restrict.langs.push({
                        language: this.langs[i].lang,
                        time_limit: this.langs[i].time,
                        memory_limit: this.langs[i].memory * 1024
                    })
                }

                formData.append('restrict', JSON.stringify(restrict))

                // add files
                let nfileList = this.nfileList
                for (let i=0; i<nfileList.length; i++) {
                    console.log("add file ", nfileList[i].name)
                    formData.append(nfileList[i].name, nfileList[i].raw)
                }
                
                formData.append('upload_new', this.problem_data_upload)
                
                // 更新数据文件
                uploadProblem(formData).then(res=>{
                    let data0 = res.data
                    console.log(data0)
                })
                    

                updateProblem(this.id, this.guid, this.title, this.abstract, this.description, this.langs, enabled).then(response=>{
                    let data = response.data
                    if (data.status != 'success' )
                        console.log(data.info)
                    else
                        alert('update problem success')
                }).catch(e=>{
                    console.log(e)
                })
            },
            deleteProblem () {
                if (this.guid != '' && this.guid != null) {
                    let formData = new FormData()
                    formData.append('problemGUID', this.guid)
                    formData.append('restrict', JSON.stringify({
                        validate: false
                    }))
                    uploadProblem(formData).then(res=>{
                        let data = res.data
                        if (data.status != 'success') {
                            alert('delete files failed')
                            console.log(data)
                        } else {
                            this.$router.push({path: '/front/default_problem'})
                        }
                    })
                }

                deleteProblem(this.id).then( response=>{
                    let data = response.data
                    if (data.status == 'success')
                        alert('delete problem success')
                    else {
                        alert('delete problem error')
                    }
                }).catch(e=>{
                    console.log(e)
                })
            },
            getGuid() {
                if (this.guid == '' || this.guid == null)
                    return '未提交'
                else return this.guid
            },
            reload () {
                this.uploadReload = false
                this.$nextTick(()=>(this.uploadReload = true))
            },
            get_title() {
                return this.tmp_id == '' ? '新 增': '编 辑'
            },
            add_new_test_code() {
                this.dialogVisible = true
                this.tmp_compiler = ''
                this.tmp_abstract = ''
                this.tmp_code = ''
                this.tmp_id = ''
            },
            edit_test_code(id, compiler, abstract, code) {
                this.dialogVisible = true
                this.tmp_compiler = compiler
                this.tmp_abstract = abstract
                this.tmp_code = code
                this.tmp_id = id
            },
            reset_dialog() {
                let id = this.tmp_id
                for (let i=0; i<this.test_codes.length; i++) {
                    if (this.test_codes[i].id == id) {
                        this.tmp_compiler = this.test_codes[i].compiler
                        this.tmp_abstract = this.test_codes[i].abstract
                        this.tmp_code = this.test_codes[i].code
                        break
                    }
                }
            },
            before_dialog_close() {
                this.tmp_compiler = ''
                this.tmp_abstract = ''
                this.tmp_code = ''
                this.tmp_id = ''
            },
            update_test_code(oid, op) {
                let id = this.tmp_id
                if (oid) {
                    id = oid
                }
                let operate = id==''?'add':'upd'
                if (op) {
                    operate = op
                }
                updateTestCode(id, operate, {
                    compiler: this.tmp_compiler,
                    abstract: this.tmp_abstract,
                    code: this.tmp_code,
                    problem_id: this.guid
                }).then(resp=> {
                    let data = resp.data
                    if (data.status == 'success') {
                        this.$message({
                            showClose: true,
                            message: '操作成功',
                            type: 'success'
                        })
                        this.dialogVisible = false
                    } else {
                        this.$message({
                            showClose: true,
                            message: '操作失败',
                            type: 'error'
                        })
                        console.log(data)
                    }

                    this.fetchData()
                }).catch(err=>{
                    this.$message({
                        showClose: true,
                        message: '操作失败',
                        type: 'error'
                    })
                    console.log(err)
                })
            }
        }
    }
</script>

<style scoped>
    th{
        text-align: center;
        border: solid #2aabd2;
    }
    .uploader-example {
        width: 880px;
        padding: 15px;
        margin: 40px auto 0;
        font-size: 12px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .4);
    }
    .uploader-example .uploader-btn {
        margin-right: 4px;
    }
    .uploader-example .uploader-list {
        max-height: 440px;
        overflow: auto;
        overflow-x: hidden;
        overflow-y: auto;
    }
</style>
