import request from '@/utils/request'

export function get_judger_list () {
  return request({
    url: '/judger/judger_list',
    method: 'get'
  })
}

export function operate_judger_api (host, container, user, op, extra) {
  return request({
    url: '/judger/judger_operate',
    method: 'POST',
    data: {
        machine: host,
        container: container,
        user: user,
        operation: op,
        extra: extra
    }
  })
}