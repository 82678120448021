<template>
    <div id="config-detail">
        <div class="container">
            <div class="row clearfix">
                <el-tabs v-model="active_name" type="border-card">
                    <el-tab-pane label="详情" name="1">
                        <div class="panel-heading">
                            <h3 class="panel-title">
                                {{add == 0 ? '编辑': '新增'}}
                            </h3>
                        </div>
                        <div>
                            <el-form label-width="150px">
                                <el-form-item label="编译语言：">
                                    <el-input v-model="language" />
                                </el-form-item>
                                <el-form-item label="编译版本：">
                                    <el-input v-model="version" />
                                </el-form-item>
                                <el-form-item label="编译器：">
                                    <el-input v-model="compiler">
                                        <template slot="prepend">唯一标识（必填）</template>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="内存限制：">
                                    <el-input v-model="memory_limit">
                                        <slot slot="suffix" class="input-slot">KB</slot>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="保留内存：">
                                    <el-input v-model="memory_reserve">
                                        <slot slot="suffix" class="input-slot">KB</slot>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="时间限制：">
                                    <el-input v-model="time_limit">
                                        <slot slot="suffix" class="input-slot">ms</slot>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="保留时间：">
                                    <el-input v-model="time_reserve">
                                        <slot slot="suffix" class="input-slot">ms</slot>
                                    </el-input>
                                </el-form-item>
                            </el-form>
                            编译及执行命令
                            <el-form label-width="150px">
                                <el-form-item label="执行参数个数：">
                                    <el-input :readonly="true" v-model="exec_cmds_argc" />
                                </el-form-item>
                                <el-form-item label="代码后缀：">
                                    <el-input v-model="extention" />
                                </el-form-item>
                                <el-form-item label="进程限制：">
                                    <el-input v-model="process_limit" />
                                </el-form-item>
                            </el-form>
                            <el-row>
                                <el-form :inline="true">
                                    <el-col :span="50" :offset="1">
                                        <el-form-item label="执行命令：" />
                                        <el-tag v-for="tag in exec_cmds" closable :disable-transitions="false" @close="handleClose('exec_cmds' ,tag)">
                                            {{tag}}
                                        </el-tag>
                                        <el-input class="input-new-tag" v-if="check_valid('exec_input')" v-model="inputValue" ref="exec_input" size="small" @keyup.enter.native="handleInputConfirm('exec_cmds')" @blur="handleInputConfirm('exec_cmds')">
                                        </el-input>
                                        <el-button v-else class="button-new-tag" size="small" @click="showInput('exec_input')">+ 新参数</el-button>
                                    </el-col>
                                </el-form>
                            </el-row>
                            <el-row>
                                <el-form :inline="true">
                                    <el-col :span="50" :offset="1">
                                        <el-form-item label="已有参数：" />
                                        <el-tag :key="tag" v-for="tag in exist_args" :disable-transitions="false">
                                            {{tag}}
                                        </el-tag>
                                    </el-col>
                                </el-form>
                            </el-row>
                            <el-row>
                                <el-form :inline="true">
                                    <el-col :span="50" :offset="1">
                                        <el-form-item label="生成参数：" />
                                        <el-button size="small" @click="add_new_arg">新增参数</el-button>
                                    </el-col>
                                </el-form>
                            </el-row>
                            <div :inline="true" v-for="(item, index) in new_args">
                                <el-row>
                                    <el-form :inline="true">
                                        <el-col :span="50" :offset="3">
                                            <el-form-item label="参数名:">
                                                <el-input v-model="item.name"></el-input>
                                            </el-form-item>
                                            <el-tag :key="tag" v-for="tag in item.list" closable :disable-transitions="false" @close="handleClose('new_arg' ,tag, 'list', item)">
                                                {{tag}}
                                            </el-tag>
                                            <el-input class="input-new-tag" v-if="check_valid('new_arg_input'+index)" v-model="inputValue" :ref="'new_arg_input'+index" size="small" @keyup.enter.native="handleInputConfirm('new_arg', 'list', item)" @blur="handleInputConfirm('new_arg', 'list', item)">
                                            </el-input>
                                            <el-button v-else class="button-new-tag" size="small" @click="showInput('new_arg_input'+index, true)">+ 新参数</el-button>
                                            <el-button size="small" @click="remove_new_arg(index)">-移除</el-button>
                                        </el-col>
                                        <el-col :span="50" :offset="4">
                                            <el-form-item label="实际参数格式:">
                                                <span> {{item.name }} = {{ get_str_from_array(item.list, '') }}</span>
                                            </el-form-item>
                                        </el-col>
                                    </el-form>
                                </el-row>
                            </div>
                            <el-row>
                                <el-form :inline="true">
                                    <el-col :span="50" :offset="1">
                                        <el-form-item label="编译命令：" />
                                        <el-button size="small" @click="add_compile_cmds">新增命令</el-button>
                                    </el-col>
                                </el-form>
                            </el-row>
                            <div v-for="(item, index) in compile_cmds">
                                <el-row>
                                    <el-form :inline="true">
                                        <el-col :span="70" :offset="3">
                                            <el-form-item label="命令"> {{index+1}}:
                                            </el-form-item>
                                            <el-tag v-for="tag in item.list" closable :disable-transitions="false" @close="handleClose('compile_cmd' ,tag, 'list', item)">
                                                {{tag}}
                                            </el-tag>
                                            <el-input class="input-new-tag" v-if="check_valid('compile_cmd_input'+index)" v-model="inputValue" :ref="'compile_cmd_input'+index" size="small" @keyup.enter.native="handleInputConfirm('compile_cmd', 'list', item)" @blur="handleInputConfirm('compile_cmd', 'list', item)">
                                            </el-input>
                                            <el-button v-else class="button-new-tag" size="small" @click="showInput('compile_cmd_input'+index, true)">+ 新参数</el-button>
                                            <el-button size="small" @click="remove_compile_cmd(index)">-移除</el-button>
                                            <el-button size="small" @click="add_compile_cmds(index)">新增命令</el-button>
                                        </el-col>
                                    </el-form>
                                </el-row>
                                <el-row>
                                    <el-form :inline="true">
                                        <el-col :span="70" :offset="3">
                                            <el-form-item label="命令"> {{index+1}} 环境参数：</el-form-item>
                                            <el-button size="small" @click="add_extra_arg(item)">新增环境参数</el-button>
                                        </el-col>
                                    </el-form>
                                </el-row>
                                <div v-for="(nitem, nindex) in item.extra">
                                    <el-row>
                                        <el-form :inline="true">
                                            <el-col :span="70" :offset="4">
                                                <el-form-item label="参数名:">
                                                    <el-select v-model="nitem.name" placeholder="请选择">
                                                        <el-option v-for="item in env_options" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="参数值:">
                                                    <el-input v-model="nitem.value"></el-input>
                                                </el-form-item>
                                                <el-button size="small" @click="remove_extra_arg(item, nindex)">-移除</el-button>
                                            </el-col>
                                        </el-form>
                                    </el-row>
                                </div>
                            </div>
                            <el-row>
                                <el-form :inline="true">
                                    <el-col :span="50" :offset="1">
                                        <el-form-item label="输出文件：" />
                                        <el-tag :key="tag" v-for="tag in output_files" closable :disable-transitions="false" @close="handleClose('output_files' ,tag)">
                                            {{tag}}
                                        </el-tag>
                                        <el-input class="input-new-tag" v-if="check_valid('outputfile_input')" v-model="inputValue" ref="outputfile_input" size="small" @keyup.enter.native="handleInputConfirm('output_files')" @blur="handleInputConfirm('output_files')">
                                        </el-input>
                                        <el-button v-else class="button-new-tag" size="small" @click="showInput('outputfile_input')">+ 新参数</el-button>
                                    </el-col>
                                </el-form>
                            </el-row>
                            <el-row>
                                <el-button round @click="update_compiler"> {{add == 0 ? '修改': '新增'}}</el-button>
                            </el-row>
                        </div>
                    </el-tab-pane>
                </el-tabs>
                <el-divider></el-divider>
            </div>
        </div>
    </div>
</template>
<script>
import { updateConfig, getCompilerDetail } from '../../api/config.js'
export default {
    data() {
        return {
            add: 1,

            inputVisible: false,
            inputValue: '',
            op_ref: '',
            exist_args: ["$file", "$file_name", "$file_dir"],

            active_name: "1",
            language: 'C',
            version: '4.8.5',
            compiler: 'gcc-4.8',

            memory_limit: 32767,
            memory_reserve: 1024,
            time_limit: 1000,
            time_reserve: 100,

            exec_cmds: ["$execFile"],
            exec_cmds_argc: 0,
            extention: 'c',
            process_limit: 1,

            env_options: [{
                    "value": "timeout",
                    "label": "超时时间"
                },
                {
                    "label": "执行目录",
                    "value": "cwd"
                },
                {
                    "label": "Shell执行",
                    "value": "shell"
                }
            ],

            env_options_valid: {
                "timeout": ["number", "超时时间"],
                "cwd": ["string", "执行目录"],
                "shell": ["boolean", "Shell执行"]
            },

            output_files: ["$execFile"],

            new_args: [
                { name: 'execFile', list: ["$file_dir", "/", "$file_name", ".exec"] }
            ],

            compile_cmds: [{
                list: ["gcc", "$file", "-o", "$execFile", "-std=c11", "-O2", "-lm"],
                extra: [
                    { name: "timeout", value: 5000 }
                ]
            }],
        }
    },
    watch: {
        exec_cmds(val) {
            this.exec_cmds_argc = val.length
        }
    },
    mounted() {
        if ('compiler' in this.$route.query) {
            this.compiler = this.$route.query.compiler
            this.add = 0
        }

        this.fetchData()
        this.exec_cmds_argc = this.exec_cmds.length
    },
    methods: {
        add_new_arg() {
            this.new_args.push({ name: 'change me', list: [] })
        },
        remove_new_arg(index) {
            this.new_args.splice(index, 1)
        },
        add_extra_arg(item) {
            item.extra.push({ name: 'change me', value: '' })
        },
        remove_extra_arg(item, index) {
            item.extra.splice(index, 1)
        },
        add_compile_cmds(index) {
            if (typeof(index) == 'number')
                this.compile_cmds.splice(index + 1, 0, { list: [], extra: [] })
            else
                this.compile_cmds.push({ list: [], extra: [] })
        },
        remove_compile_cmd(index) {
            this.compile_cmds.splice(index, 1)
        },
        handleClose(arr, tag, key, obj) {
            if (key && obj)
                obj[key].splice(obj[key].indexOf(tag), 1);
            else
                this[arr].splice(this[arr].indexOf(tag), 1);
        },
        check_valid(ref) {
            return this.inputVisible && ref == this.op_ref
        },
        showInput(input_ref, no_ref) {
            this.op_ref = input_ref
            this.inputVisible = true;

            this.$nextTick(_ => {
                if (!no_ref)
                    this.$refs[input_ref].$refs.input.focus();
            });
        },

        handleInputConfirm(arr, key, obj) {
            let inputValue = this.inputValue;
            if (inputValue) {
                if (key && obj) {
                    obj[key].push(inputValue)
                } else
                    this[arr].push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },

        get_str_from_array(arr, sep) {
            let str = ''
            for (let i = 0; i < arr.length; i++) {
                let tmp_str = arr[i]
                if (tmp_str.substr(0, 1) == '$')
                    tmp_str = '${' + tmp_str.substr(1) + '}'
                if (i != 0)
                    str = str + sep + tmp_str
                else
                    str = str + tmp_str
            }
            return str
        },
        fetchData() {
            if (this.compiler && this.compiler != '')
                getCompilerDetail(this.compiler).then(resp => {
                    let body = resp.data
                    if (body.status != 'success') {
                        console.log(body)
                    } else {
                        let data = body["data"]
                        this.compiler = data.compiler
                        this.language = data.language
                        this.version = data.version
                        this.memory_limit = data.memory_limit
                        this.memory_reserve = data.memory_reserve
                        this.time_limit = data.time_limit
                        this.time_reserve = data.time_reserve
                        this.exec_cmds = data.exec_cmds
                        this.exec_cmds_argc = data.exec_cmds_argc
                        this.extention = data.extention
                        this.process_limit = data.process_limit
                        this.output_files = data.output_files
                        this.new_args = data.gen_args
                        this.compile_cmds = data.compile_cmds
                    }
                }).catch(e => {
                    console.log(e)
                })
        },

        update_compiler() {
            if (this.compiler == '' || !this.compiler)
                alert('编译器未填写，请重新填写后提交')
            else {
                let env_options_valid = this.env_options_valid
                for (let i=0; i<this.compile_cmds.length; i++) {
                    let extra = this.compile_cmds[i].extra
                    for (let j=0; j<extra.length; j++)
                    {
                        let name = extra[j].name
                        let value = extra[j].value
                        let check_type = env_options_valid[name]
                        if (check_type[0] == 'number') {
                            let pvalue = parseInt(value)
                            let pvalue_str = pvalue.toString()
                            // console.log(name, ' ', value, pvalue_str, ' ', value == pvalue_str)
                            if (value == pvalue_str) {
                                extra[j].value = pvalue
                            } else {
                                this.$message({
                                  message: '环境参数类型错误： 参数 ' + check_type[1] + " 无法转换为整数",
                                  type: 'warning'
                                });
                                return 
                            }
                        } else if (check_type[0] == 'boolean') {
                            if (typeof(value) == 'boolean') {
                            } else
                            if (value == 'true') 
                                extra[j].value = true
                            else if (value == 'false')
                                extra[j].value = false
                            else {
                                this.$message({
                                  message: '环境参数类型错误： 参数 ' + check_type[1] + " 只能为true或false",
                                  type: 'warning'
                                });
                                return 
                            }
                        } else {
                            if (value.length == 0) {
                                this.$message({
                                  message: '环境参数类型错误： 参数 ' + check_type[1] + " 值不能为空",
                                  type: 'warning'
                                });
                                return 
                            }
                        }
                    }
                }

                let data = {
                    compiler: this.compiler,
                    language: this.language,
                    version: String(this.version),

                    memory_limit: parseInt(this.memory_limit),
                    memory_reserve: parseInt(this.memory_reserve),
                    time_limit: parseInt(this.time_limit),
                    time_reserve: parseInt(this.time_reserve),

                    exec_cmds: this.exec_cmds,
                    exec_cmds_argc: this.exec_cmds_argc,
                    extention: this.extention,
                    process_limit: parseInt(this.process_limit),

                    output_files: this.output_files,
                    gen_args: this.new_args,
                    compile_cmds: this.compile_cmds
                }

                updateConfig(data).then(resp => {
                    let data = resp.data
                    if (data.status == 'success') {
                        this.$message({
                            showClose: true,
                            message: '操作成功',
                            type: 'success'
                        })
                    } else {
                        this.$message({
                            showClose: true,
                            message: '操作失败',
                            type: 'error'
                        })
                        console.log(data)
                    }
                }).catch(e => {
                    console.log(e)
                })
            }
        }
    }
}
</script>
<style scoped>
.el-tag+.el-tag {
    margin-left: 10px;
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
</style>